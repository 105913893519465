<template>
    <div>
        <v-expansion-panels class="mb-4 elevation-1">
            <v-expansion-panel>
                <v-expansion-panel-header
                    color="#eeeeee"
                    class="text-h5 my-0 font-weight-bold"
                >
                    Shipment #{{ iterator }}
                    <template v-slot:actions>
                        <v-btn
                            v-if="!shipmentIsNew && !view"
                            icon
                            @click.stop="editable = !editable"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="!view" icon @click.stop="deleteShipment()">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-icon>$expand</v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <!-- first-form -->
                    <v-form v-model="valid" class="py-2" :disabled="!editable">
                        <v-row no-gutters class="mt-4">
                            <v-col
                                cols="12"
                                xl="6"
                                lg="6"
                                md="6"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.invoiceNumber"
                                    prepend-icon="mdi-invoice-text-outline"
                                    label="Invoice Number"
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="6"
                                lg="6"
                                md="6"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.trm"
                                    prepend-icon="mdi-currency-usd"
                                    label="TRM"
                                    type="number"
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    @input="
                                        event => {
                                            shipmentFormData.trm =
                                                parseFloat(event) || 0
                                            getTotalValues()
                                            structureData()
                                        }
                                    "
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                    <!-- data-table -->
                    <v-data-table
                        class="mt-4 elevation-1"
                        :headers="filteredHeaders"
                        :items="itemsFromShipment"
                        item-value="id"
                        :loading="quoteLoading"
                        :mobile-breakpoint="0"
                        disable-pagination
                        hide-default-footer
                        fixed-header
                    >
                        <!--ITEMS-->
                        <template v-slot:[`item.description`]="{ item }">
                            <div v-if="!item.code" class="d-flex justify-right">
                                <v-select
                                    :disabled="!editable"
                                    class="text-body-2 font-weight-regular pb-4"
                                    dense
                                    single-line
                                    v-model="item.description"
                                    :items="itemsAvailable"
                                    item-text="description"
                                    hide-details
                                    hide-selected
                                    :rules="[rules.required]"
                                    @change="
                                        () => {
                                            pushItem(item)
                                            getTotalValues()
                                            structureData(item.code)
                                        }
                                    "
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                ></v-select>
                            </div>
                            <div v-else class="d-flex justify-center">
                                <p class="text-capitalize">
                                    {{ item.description }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.ci`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!editable"
                                    class="font-weight-regular text-body-2"
                                    dense
                                    single-line
                                    v-model="item.ci"
                                    regular
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                ></v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.oc`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="text-capitalize">
                                    {{ item.oc }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.weight`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!editable"
                                    class="font-weight-regular text-body-2"
                                    type="number"
                                    dense
                                    single-line
                                    v-model="item.weight"
                                    regular
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    @input="
                                        event => {
                                            item.weight = parseFloat(event) || 0
                                            getTotalValues()
                                            structureData()
                                        }
                                    "
                                    suffix="kg"
                                ></v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.quantity`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!editable"
                                    class="font-weight-regular text-body-2"
                                    type="number"
                                    dense
                                    single-line
                                    v-model="item.quantity"
                                    regular
                                    :rules="[
                                        rules.validateNumber,
                                        rules.required,
                                        rules.quantityRange(item),
                                    ]"
                                    @input="
                                        event => {
                                            item.quantity =
                                                parseFloat(event) || 0
                                            getTotalValues()
                                            structureData(item.code)
                                        }
                                    "
                                ></v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.unitWeight`]="{ item }">
                            <div class="d-flex justify-center">
                                <p class="text-capitalize">
                                    {{
                                        item.weight && item.quantity
                                            ? (
                                                  item.weight / item.quantity
                                              ).toFixed(2)
                                            : '-'
                                    }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.unitPrice`]="{ item }">
                            <div>
                                <v-text-field
                                    :disabled="!editable"
                                    class="font-weight-regular text-body-2"
                                    type="number"
                                    dense
                                    single-line
                                    v-model="item.unitPrice"
                                    regular
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    @input="
                                        event => {
                                            item.unitPrice =
                                                parseFloat(event) || 0
                                            getTotalValues()
                                            structureData(item.code)
                                        }
                                    "
                                    suffix="$"
                                ></v-text-field>
                            </div>
                        </template>
                        <template v-slot:[`item.totalPrice`]="{ item }">
                            <div class="d-flex justify-end">
                                <p class="text-capitalize">
                                    {{
                                        formatCurrency(
                                            'USD',
                                            item.totalPrice || 0
                                        )
                                    }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.valueInCOP`]="{ item }">
                            <div class="d-flex justify-end">
                                <p class="text-capitalize">
                                    {{
                                        formatCurrency(
                                            'COP',
                                            item.totalPrice *
                                                shipmentFormData.trm || 0
                                        )
                                    }}
                                </p>
                            </div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <div class="d-flex justify-center my-0">
                                <v-btn
                                    v-if="editable"
                                    small
                                    icon
                                    class="mr-2"
                                    @click="deleteItem(item)"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </div>
                        </template>
                        <!--Body append-->
                        <template v-slot:[`body.append`]>
                            <!--"+ Add New Item"-->

                            <td :colspan="headers.length" class="text-center">
                                <v-btn
                                    @click="addNewItem()"
                                    block
                                    plain
                                    :disabled="
                                        !editable ||
                                            validateNewShipment === 0 ||
                                            validateItems.length === 0
                                    "
                                >
                                    <v-icon small>
                                        mdi-plus
                                    </v-icon>
                                    Add New Item
                                </v-btn>
                            </td>

                            <!--FOOTER-->
                            <tr :style="{ 'background-color': '#eeeeee' }">
                                <td
                                    v-if="request.modality === 'Plan Vallejo'"
                                    class="font-weight-bold"
                                ></td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold justify-end">
                                    TOTALS
                                </td>
                                <td class="font-weight-bold">
                                    <p
                                        class="d-flex justify-center align-right my-0"
                                    >
                                        {{ totalWeight.toFixed(2) }} kg
                                    </p>
                                </td>
                                <td class="font-weight-bold">
                                    <p
                                        class="d-flex justify-center align-right my-0"
                                    >
                                        {{ totalQuantity.toFixed(2) }}
                                    </p>
                                </td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold"></td>
                                <td class="font-weight-bold">
                                    <p
                                        class="d-flex justify-end align-right my-0"
                                    >
                                        {{ formatCurrency('USD', totalUSD) }}
                                    </p>
                                </td>
                                <td class="font-weight-bold">
                                    <p
                                        class="d-flex justify-end align-right my-0"
                                    >
                                        {{ formatCurrency('COP', totalCOP) }}
                                    </p>
                                </td>
                                <td class="font-weight-bold"></td>
                            </tr>
                        </template>
                    </v-data-table>
                    <!-- second-form -->
                    <v-form v-model="valid2" class="py-2" :disabled="!editable">
                        <v-row no-gutters class="mt-4">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-select
                                    v-model="shipmentFormData.shipmentType"
                                    :items="shipmentData.shipmentType"
                                    label="Shipment Type"
                                    hide-details
                                    prepend-icon="mdi-plane-train"
                                    hide-selected
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-1 mt-0">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="shipmentFormData.depDate"
                                            label="Departure Date"
                                            prepend-icon="mdi-airplane-clock"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="shipmentFormData.depDate"
                                        @input="
                                            () => {
                                                structureData(), (menu = false)
                                            }
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-select
                                    v-model="shipmentFormData.arrivalPort"
                                    :items="shipmentData.arrivalPort"
                                    label="Port of Arrival"
                                    hide-details
                                    prepend-icon="mdi-plane-train"
                                    hide-selected
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.ShippingLine"
                                    prepend-icon="mdi-shipping-pallet"
                                    label="Shipping Line"
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.motonave"
                                    prepend-icon="mdi-truck-cargo-container"
                                    label="Motonave"
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-1 mt-0">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.transitTime"
                                    prepend-icon="mdi-calendar-clock"
                                    label="Transit Time in days"
                                    type="number"
                                    @input="
                                        event => {
                                            shipmentFormData.transitTime =
                                                parseFloat(event) || 0
                                            structureData()
                                        }
                                    "
                                    :rules="[rules.required, rules.number1]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu3"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="
                                                shipmentFormData.estimatedDateArrival
                                            "
                                            label="Estimated Date of Arrival"
                                            prepend-icon="mdi-calendar-check"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="
                                            shipmentFormData.estimatedDateArrival
                                        "
                                        @input="
                                            () => {
                                                structureData(), (menu3 = false)
                                            }
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.numberDim"
                                    prepend-icon="mdi-invoice-import-outline"
                                    label="DIM Number"
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-1 mt-0">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu1"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="
                                                shipmentFormData.acceptanceDateDIM
                                            "
                                            label="Acceptance Date DIM"
                                            prepend-icon="mdi-calendar-check"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="
                                            shipmentFormData.acceptanceDateDIM
                                        "
                                        @input="
                                            () => {
                                                structureData(), (menu1 = false)
                                            }
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu4"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="shipmentFormData.DateOfDIM"
                                            label="Dim Lift Date"
                                            prepend-icon="mdi-calendar-check"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="shipmentFormData.DateOfDIM"
                                        @input="
                                            () => {
                                                structureData(), (menu4 = false)
                                            }
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.ivaDim"
                                    prepend-icon="mdi-cash"
                                    label="IVA DIM"
                                    type="number"
                                    @input="
                                        event => {
                                            shipmentFormData.ivaDim =
                                                parseFloat(event) || 0
                                            structureData()
                                        }
                                    "
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-1 mt-0">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.containerNumber"
                                    prepend-icon="mdi-train-car-container"
                                    label="Container Number"
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-select
                                    v-model="shipmentFormData.containerSize"
                                    :items="shipmentData.containerSize"
                                    label="Container Size"
                                    hide-details
                                    prepend-icon="mdi-train-car-container"
                                    hide-selected
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    :menu-props="{
                                        bottom: true,
                                        offsetY: true,
                                    }"
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.transportDoc"
                                    prepend-icon="mdi-text-box-outline"
                                    label="Transport Document"
                                    @input="structureData()"
                                    :rules="[rules.required]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu6"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="
                                                shipmentFormData.dateDocTransport
                                            "
                                            label="Date of Transport Document"
                                            prepend-icon="mdi-calendar-check"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="
                                            shipmentFormData.dateDocTransport
                                        "
                                        @input="
                                            () => {
                                                structureData(), (menu6 = false)
                                            }
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-1 mt-0">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.fobDimValue"
                                    prepend-icon="mdi-currency-usd"
                                    label="FOB DIM Value"
                                    type="number"
                                    @input="
                                        event => {
                                            shipmentFormData.fobDimValue =
                                                parseFloat(event) || 0
                                            structureData()
                                        }
                                    "
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.unitFOB"
                                    prepend-icon="mdi-currency-usd"
                                    label="FOB Unit Value"
                                    type="number"
                                    @input="
                                        event => {
                                            shipmentFormData.unitFOB =
                                                parseFloat(event) || 0

                                            structureData()
                                        }
                                    "
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-1 mt-0">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.freight"
                                    prepend-icon="mdi-currency-usd"
                                    type="number"
                                    @input="
                                        event => {
                                            shipmentFormData.freight =
                                                parseFloat(event) || 0
                                            structureData()
                                        }
                                    "
                                    label="Freight Value"
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.insurance"
                                    prepend-icon="mdi-currency-usd"
                                    label="Insurance"
                                    type="number"
                                    @input="
                                        event => {
                                            shipmentFormData.insurance =
                                                parseFloat(event) || 0
                                            structureData()
                                        }
                                    "
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    v-model="shipmentFormData.otherCosts"
                                    prepend-icon="mdi-currency-usd"
                                    label="Other Costs"
                                    type="number"
                                    @input="
                                        event => {
                                            shipmentFormData.otherCosts =
                                                parseFloat(event) || 0
                                            structureData()
                                        }
                                    "
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="my-4 mt-4">
                            <v-spacer></v-spacer>
                        </v-row>
                        <v-row no-gutters class="my-1 mt-0">
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu2"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="
                                                shipmentFormData.realDateArrival
                                            "
                                            label="Actual Date of Arrival"
                                            prepend-icon="mdi-calendar-check"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="
                                            shipmentFormData.realDateArrival
                                        "
                                        @input="
                                            () => {
                                                structureData(), (menu2 = false)
                                            }
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu5"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="
                                                shipmentFormData.enteredDate
                                            "
                                            label="Date Entered Warehouse"
                                            prepend-icon="mdi-calendar-check"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="shipmentFormData.enteredDate"
                                        @input="
                                            () => {
                                                structureData(), (menu5 = false)
                                            }
                                        "
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col></v-col>
                            <v-col
                                cols="12"
                                xl="3"
                                lg="3"
                                md="3"
                                sm="6"
                                class="pl-2"
                            >
                                <v-text-field
                                    readonly
                                    disabled
                                    v-model="computedTotalCustoms"
                                    prepend-icon="mdi-currency-usd"
                                    label="Total Customs Value"
                                    :rules="[
                                        rules.required,
                                        rules.validateNumber,
                                    ]"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row class="pb-1 pt-4 mt-3">
                        <div class="d-flex align-right pl-4">
                            <strong>ATTACHMENTS: </strong>
                        </div>
                        <v-spacer />
                    </v-row>
                    <div class="pb-1 pt-3 mt-3">
                        <!-- invoice-files -->
                        <v-row ref="invoice-files">
                            <v-col cols="12" class="py-0">
                                <div class="d-flex my-2">
                                    <div class="d-flex align-center pl-1">
                                        <strong>INVOICE: </strong>
                                    </div>
                                    <div class="d-flex align-center flex-wrap">
                                        <v-btn
                                            color="transparent"
                                            elevation="0"
                                            small
                                            class="ma-1 text-capitalize text--darken-2  pa-2"
                                            v-for="(file,
                                            i) in shipmentFormData.attachedInvoice"
                                            :key="i"
                                        >
                                            <v-icon
                                                class="mr-1"
                                                color="primary"
                                                :style="{ 'font-size': '17px' }"
                                            >
                                                mdi-paperclip
                                            </v-icon>
                                            <span
                                                @click.stop="
                                                    openAttachedFile(
                                                        'attachedInvoice',
                                                        file
                                                    )
                                                "
                                                :style="{
                                                    'font-size': '11px',
                                                    color: '#2b81d6',
                                                    'text-decoration':
                                                        'underline',
                                                    cursor: 'pointer',
                                                }"
                                                >{{ file }}</span
                                            >
                                            <v-icon
                                                v-if="!view"
                                                :disabled="!editable"
                                                @click.stop="
                                                    deleteFile(
                                                        'attachedInvoice',
                                                        file
                                                    )
                                                "
                                                class="ml-1"
                                                small
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            :disabled="!editable"
                                            @click.stop="
                                                onButtonClick('attachedInvoice')
                                            "
                                            color="accent"
                                            icon
                                            class="ml-2"
                                            plain
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <input
                                            ref="attachedInvoice"
                                            class="d-none"
                                            type="file"
                                            name="attachedInvoice"
                                            @change="onFileChanged($event)"
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- dim-files -->
                        <v-row ref="dim-files">
                            <v-col cols="12" class="py-0">
                                <div class="d-flex my-2">
                                    <div class="d-flex align-center pl-1">
                                        <strong>DIM: </strong>
                                    </div>
                                    <div class="d-flex align-center flex-wrap">
                                        <v-btn
                                            color="transparent"
                                            elevation="0"
                                            small
                                            class="ma-1 text-capitalize text--darken-2  pa-2"
                                            v-for="(file,
                                            i) in shipmentFormData.attachedDIM"
                                            :key="i"
                                        >
                                            <v-icon
                                                class="mr-1"
                                                color="primary"
                                                :style="{ 'font-size': '17px' }"
                                            >
                                                mdi-paperclip
                                            </v-icon>
                                            <span
                                                @click.stop="
                                                    openAttachedFile(
                                                        'attachedDIM',
                                                        file
                                                    )
                                                "
                                                :style="{
                                                    'font-size': '11px',
                                                    color: '#2b81d6',
                                                    'text-decoration':
                                                        'underline',
                                                    cursor: 'pointer',
                                                }"
                                                >{{ file }}</span
                                            >
                                            <v-icon
                                                v-if="!view"
                                                :disabled="!editable"
                                                @click.stop="
                                                    deleteFile(
                                                        'attachedDIM',
                                                        file
                                                    )
                                                "
                                                class="ml-1"
                                                small
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            :disabled="!editable"
                                            @click.stop="
                                                onButtonClick('attachedDIM')
                                            "
                                            color="accent"
                                            icon
                                            class="ml-2"
                                            plain
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <input
                                            ref="attachedDIM"
                                            class="d-none"
                                            type="file"
                                            name="attachedDIM"
                                            @change="onFileChanged($event)"
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- transport-files -->
                        <v-row ref="transport-files">
                            <v-col cols="12" class="py-0">
                                <div class="d-flex my-2">
                                    <div class="d-flex align-center pl-1">
                                        <strong>DOC TRANSPORT: </strong>
                                    </div>
                                    <div class="d-flex align-center flex-wrap">
                                        <v-btn
                                            color="transparent"
                                            elevation="0"
                                            small
                                            class="ma-1 text-capitalize text--darken-2  pa-2"
                                            v-for="(file,
                                            i) in shipmentFormData.attachedDocTransport"
                                            :key="i"
                                        >
                                            <v-icon
                                                class="mr-1"
                                                color="primary"
                                                :style="{ 'font-size': '17px' }"
                                            >
                                                mdi-paperclip
                                            </v-icon>
                                            <span
                                                @click.stop="
                                                    openAttachedFile(
                                                        'attachedDocTransport',
                                                        file
                                                    )
                                                "
                                                :style="{
                                                    'font-size': '11px',
                                                    color: '#2b81d6',
                                                    'text-decoration':
                                                        'underline',
                                                    cursor: 'pointer',
                                                }"
                                                >{{ file }}</span
                                            >
                                            <v-icon
                                                v-if="!view"
                                                :disabled="!editable"
                                                @click.stop="
                                                    deleteFile(
                                                        'attachedDocTransport',
                                                        file
                                                    )
                                                "
                                                class="ml-1"
                                                color="grey darken-2"
                                                small
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            :disabled="!editable"
                                            @click.stop="
                                                onButtonClick(
                                                    'attachedDocTransport'
                                                )
                                            "
                                            color="accent"
                                            icon
                                            class="ml-2"
                                            plain
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <input
                                            ref="attachedDocTransport"
                                            class="d-none"
                                            type="file"
                                            name="attachedDocTransport"
                                            @change="onFileChanged($event)"
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        <!-- other-files -->
                        <v-row ref="other-files">
                            <v-col cols="12" class="py-0">
                                <div class="d-flex my-2">
                                    <div class="d-flex align-center pl-1">
                                        <strong>OTHER FILES: </strong>
                                    </div>
                                    <div class="d-flex align-center flex-wrap">
                                        <v-btn
                                            color="transparent"
                                            elevation="0"
                                            small
                                            class="ma-1 text-capitalize text--darken-2  pa-2"
                                            v-for="(file,
                                            i) in shipmentFormData.attachedOtherFiles"
                                            :key="i"
                                        >
                                            <v-icon
                                                class="mr-1"
                                                color="primary"
                                                :style="{ 'font-size': '17px' }"
                                            >
                                                mdi-paperclip
                                            </v-icon>

                                            <span
                                                @click.stop="
                                                    openAttachedFile(
                                                        'attachedOtherFiles',
                                                        file
                                                    )
                                                "
                                                :style="{
                                                    'font-size': '11px',
                                                    color: '#2b81d6',
                                                    'text-decoration':
                                                        'underline',
                                                    cursor: 'pointer',
                                                }"
                                                >{{ file }}</span
                                            >
                                            <v-icon
                                                v-if="!view"
                                                :disabled="!editable"
                                                @click.stop="
                                                    deleteFile(
                                                        'pattachedOtherFiles',
                                                        file
                                                    )
                                                "
                                                class="ml-1"
                                                small
                                            >
                                                mdi-close-circle
                                            </v-icon>
                                        </v-btn>
                                        <v-btn
                                            :disabled="!editable"
                                            @click.stop="
                                                onButtonClick(
                                                    'attachedOtherFiles'
                                                )
                                            "
                                            color="accent"
                                            icon
                                            class="ml-2"
                                            plain
                                        >
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                        <input
                                            ref="attachedOtherFiles"
                                            class="d-none"
                                            type="file"
                                            name="attachedOtherFiles"
                                            @change="onFileChanged($event)"
                                        />
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapMutations } from 'vuex'
export default {
    name: 'ComexShipmentForm',
    props: {
        iterator: {
            type: Number,
            default: () => 1,
        },
        maxValues: {
            type: Array,
            default: () => [],
            required: true,
        },
        request: {
            type: Object,
            default: () => {},
            required: true,
        },
        items: {
            type: Array,
            default: () => [],
            required: true,
        },
        itemsInRequest: {
            type: Array,
            default: () => [],
            required: true,
        },
        newShipment: {
            type: Boolean,
            default: () => false,
        },
        isEditable: {
            type: Boolean,
            default: () => false,
        },
        shipmentId: {
            type: String,
            required: true,
        },
        validateNewShipment: {
            type: Number,
            required: true,
        },
        formShipment: {
            type: Object,
            default: () => {},
        },
        view: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            editable: false,
            shipmentIsNew: false,
            menu: false,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            valid: false,
            valid2: false,
            shipmentFormData: {},
            shipmentData: {
                shipmentType: ['Maritime', 'Aerial'],
                arrivalPort: [
                    'Barranquilla-SPRB',
                    'Cartagena',
                    'Buenaventura',
                    'Bogotá',
                ],
                containerSize: ['20"', '40"', '45"'],
            },
            rules: {
                required: v => !!v || 'Required',
                number1: v => v > 0 || 'Error',
                number: v => v >= 0 || 'Error',
                validateNumber: v => {
                    const numberValue = parseFloat(v)
                    return (
                        (!isNaN(numberValue) && numberValue > 0) ||
                        'Invalid Number'
                    )
                },
                quantityRange: item => value => {
                    const maxValueObj = this.maxValues.find(
                        mv => mv.code === item.code
                    )
                    if (
                        maxValueObj &&
                        maxValueObj.received - maxValueObj.processed >= 0
                    ) {
                        return true
                    } else {
                        return `${value} exceeds the amount received`
                    }
                },
            },
            headers: [
                {
                    text: 'PRODUCT',
                    value: 'description',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'CI',
                    value: 'ci',
                    align: 'center',
                    sortable: false,
                    width: '100',
                },
                {
                    text: 'OC',
                    value: 'oc',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: 'WEIGHT (Kg)',
                    value: 'weight',
                    align: 'center',
                    sortable: false,
                    width: '120',
                },
                {
                    text: 'QUANTITY',
                    value: 'quantity',
                    align: 'center',
                    sortable: false,
                    width: '95',
                },
                {
                    text: 'UNIT WEIGHT (Kg)',
                    value: 'unitWeight',
                    align: 'center',
                    sortable: false,
                    width: '120',
                },
                {
                    text: 'USD VALUE PER UNIT',
                    value: 'unitPrice',
                    align: 'center',
                    sortable: false,
                    width: '120',
                },
                {
                    text: 'USD VALUE',
                    value: 'totalPrice',
                    align: 'right',
                    sortable: false,
                    width: '120',
                },
                {
                    text: 'COP VALUE',
                    value: 'valueInCOP',
                    align: 'right',
                    sortable: false,
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                },
            ],
            quoteLoading: false,
            totalWeight: 0,
            totalQuantity: 0,
            totalUSD: 0,
            totalCOP: 0,
            localAttachments: {},
            companyId: JSON.parse(localStorage.getItem('company')),
            itemsFromShipment: [],
            itemsAvailable: [],
        }
    },
    computed: {
        computedTotalCustoms() {
            const {
                fobDimValue,
                freight,
                insurance,
                otherCosts,
            } = this.shipmentFormData
            const totalCustoms =
                Number(fobDimValue || 0) +
                Number(freight || 0) +
                Number(insurance || 0) +
                Number(otherCosts || 0)
            return totalCustoms
        },
        filteredHeaders() {
            return this.headers.filter(
                header =>
                    header.value !== 'ci' ||
                    this.request.modality === 'Plan Vallejo'
            )
        },
        getTotalPrice() {
            return () => {
                return id => {
                    const item = this.itemsFromShipment.find(
                        obj => obj.code === id
                    )
                    return item ? item.quantity * item.unitPrice : 0
                }
            }
        },
        validateItems() {
            let itemsAvailable = []
            this.itemsInRequest.forEach(item => {
                const indexAvalaible = this.maxValues.findIndex(
                    obj => obj.code === item.code
                )
                if (indexAvalaible > -1) {
                    const maxValue = this.maxValues[indexAvalaible].maxValue
                    if (maxValue > 0) {
                        itemsAvailable.push({
                            description: item.description,
                            code: item.code,
                        })
                    }
                }
            })
            this.itemsFromShipment.forEach(obj => {
                const itemIndex = itemsAvailable.findIndex(
                    item => item.code === obj.code
                )
                if (itemIndex > -1) {
                    itemsAvailable.splice(itemIndex, 1)
                }
            })
            return itemsAvailable
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.editable = this.isEditable
            this.shipmentIsNew = this.newShipment
            if (!this.shipmentIsNew) {
                this.shipmentFormData = _.cloneDeep(this.formShipment)
                this.itemsFromShipment = _.cloneDeep(this.items)
            }

            this.getTotalValues()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        formatCurrency(currency, value) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: currency,
                maximumFractionDigits: 2,
            }).format(value)
        },
        getTotalValues() {
            this.totalWeight = 0
            this.totalQuantity = 0
            this.totalUSD = 0
            this.totalCOP = 0
            this.itemsFromShipment.forEach(item => {
                this.totalWeight += Number(item.weight) || 0
                this.totalQuantity += Number(item.quantity) || 0
                this.totalUSD += Number(item.totalPrice) || 0
            })
            this.totalCOP = this.totalUSD * this.shipmentFormData.trm || 0
        },
        async openAttachedFile(type, file) {
            try {
                this.loading = true
                if (this.shipmentIsNew) {
                    window.open(this.localAttachments[file].url, '_blank')
                } else {
                    this.$emit('openFile', file)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteFile(type, file) {
            try {
                this.loading = true
                if (this.shipmentIsNew) {
                    delete this.localAttachments[file]
                    this.shipmentFormData[type] = this.shipmentFormData[
                        type
                    ].filter(item => item !== file)
                } else {
                    if (this.localAttachments[file]) {
                        delete this.localAttachments[file]
                    }
                    this.shipmentFormData[type] = this.shipmentFormData[
                        type
                    ].filter(item => item !== file)
                    this.$emit('deleteFile', file)
                }
                this.structureData()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },

        onButtonClick(type) {
            if (type == 'attachedInvoice') {
                this.$refs.attachedInvoice.click()
            } else if (type == 'attachedDIM') {
                this.$refs.attachedDIM.click()
            } else if (type == 'attachedDocTransport') {
                this.$refs.attachedDocTransport.click()
            } else if (type == 'attachedOtherFiles') {
                this.$refs.attachedOtherFiles.click()
            }
        },
        async onFileChanged(e) {
            if (e.target.files.length > 0) {
                const file = e.target.files[0]
                this.localAttachments[file.name] = {
                    url: URL.createObjectURL(file),
                    file,
                    from: e.target.name,
                }
                this.shipmentFormData = {
                    ...this.shipmentFormData,
                    [e.target.name]: [
                        ...(this.shipmentFormData[e.target.name]
                            ? this.shipmentFormData[e.target.name]
                            : []),
                        file.name,
                    ],
                }
                switch (e.target.name) {
                    case 'attachedInvoice':
                        this.$refs.attachedInvoice.value = ''
                        break
                    case 'attachedDIM':
                        this.$refs.attachedDIM.value = ''
                        break
                    case 'attachedDocTransport':
                        this.$refs.attachedDocTransport.value = ''
                        break
                    case 'attachedOtherFiles':
                        this.$refs.attachedOtherFiles.value = ''
                        break
                    default:
                        return null
                }
                this.structureData()
            }
        },
        editShipment() {
            this.editable = !this.editable
        },
        deleteShipment() {
            this.$emit('deleteShipment', this.shipmentId)
        },
        structureData(code) {
            if (code) {
                const itemIndex = this.itemsFromShipment.findIndex(
                    obj => obj.code === code
                )
                if (itemIndex > -1) {
                    this.itemsFromShipment[itemIndex].totalPrice =
                        this.itemsFromShipment[itemIndex].unitPrice *
                        this.itemsFromShipment[itemIndex].quantity
                    this.getTotalValues()
                }
            }

            this.shipmentFormData.totalCOP = this.totalCOP
            this.shipmentFormData.totalUSD = this.totalUSD
            this.shipmentFormData.totalWeight = this.totalWeight
            this.shipmentFormData.totalQuantity = this.totalQuantity
            this.shipmentFormData.totalCustoms = this.computedTotalCustoms
            this.shipmentFormData.id = this.shipmentId
            if (
                this.shipmentFormData.attachedInvoice &&
                this.shipmentFormData.attachedInvoice.length === 0
            ) {
                delete this.shipmentFormData.attachedInvoice
            }
            if (
                this.shipmentFormData.attachedDIM &&
                this.shipmentFormData.attachedDIM.length === 0
            ) {
                delete this.shipmentFormData.attachedDIM
            }
            if (
                this.shipmentFormData.attachedDocTransport &&
                this.shipmentFormData.attachedDocTransport.length === 0
            ) {
                delete this.shipmentFormData.attachedDocTransport
            }
            if (
                this.shipmentFormData.attachedOtherFiles &&
                this.shipmentFormData.attachedOtherFiles.length === 0
            ) {
                delete this.shipmentFormData.attachedOtherFiles
            }
            this.$emit(
                'comexShipment',
                this.shipmentFormData,
                this.itemsFromShipment,
                this.localAttachments
            )
        },
        pushItem(description) {
            let item = null
            this.itemsInRequest.forEach(obj => {
                if (obj.description === description.description) {
                    item = { ...obj }
                }
            })
            if (item) {
                const itemIndex = this.itemsFromShipment.findIndex(
                    obj => obj.description === description.description
                )

                if (itemIndex > -1) {
                    this.itemsFromShipment.splice(itemIndex, 1, item)
                }
            }
        },
        addNewItem() {
            this.itemsAvailable = this.validateItems
            this.itemsFromShipment.push({})
        },
        deleteItem(item) {
            let itemIndex = null
            if (!item.code) {
                itemIndex = this.itemsFromShipment.findIndex(obj => !obj.code)
            } else {
                itemIndex = this.itemsFromShipment.findIndex(
                    obj => obj.code === item.code
                )
            }
            if (itemIndex > -1) {
                this.itemsFromShipment.splice(itemIndex, 1)
            }
            this.structureData()
        },
    },
}
</script>

<style>
.v-expansion-panel-header .v-btn .v-icon {
    transform: none !important;
}
</style>
